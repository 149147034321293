<template>
    <users :companyId="$store.getters['master/company'].id"/>
</template>

<script>

import Users from "@/master/views/Home/company/Users";

export default {
    name: "UsersDashboard",
    components: {
        'users': Users
    }
}
</script>

<style scoped>
</style>